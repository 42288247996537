@font-face {
  font-family: 'Europa';
  font-style: normal;
  font-weight: 300;
  font-display: block; // No fallback during the 3 first seconds
  src: url('assets/fonts/europa-light.woff'); }
@font-face {
  font-family: 'Brn';
  font-style: normal;
  font-weight: 700;
  font-display: block; // No fallback during the 3 first seconds
  src: url('assets/fonts/brn-bold.woff') format('woff'); }

@font-face {
  font-family: 'Brn';
  font-style: normal;
  font-weight: 300;
  font-display: block; // No fallback during the 3 first seconds
  src: url('assets/fonts/brn-regular.woff') format('woff'); }
