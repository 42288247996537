$prim: #2f2d3c;
$danger: #dc3545;
$selection: #7afee0;
$placeholder-color: #828b93;

$info-bg: #3a3d5d;

$skills-dots: #d7d7d7;

$experience-bg: #1e1c2f;
$experience-panel: rgba(53, 53, 65, 0.6);
$experience-panel-shadow: rgba(7, 4, 31, 0.3);

$section-padding-top: 60px;
$section-padding-bot: 100px;

$font-family-base: 'Europa', sans-serif;
$font-weight-base: 300;

$headings-font-family: 'Brn', sans-serif;
$headings-font-weight: 700;
$headings-spacing: -0.2rem;
