
#a-propos {
  min-height: 100vh;
  position: relative;
  transform-style: preserve-3d;

  .parallax {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px) scale(2);
    background: $info-bg url(assets/img/info_background.jpg) no-repeat center;
    background-size: cover; }

  h1 {
    // Boris Kubiak
    font-size: 5rem !important;
    letter-spacing: $headings-spacing; }
  h2 {
    // Dev web full-stack
    font-size: 2.1rem !important;
    font-family: $font-family-base; }

  h1, h2 {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.25); }
  p {
    font-size: 1.2em;
    letter-spacing: 0.02em;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

    a {
      color: #fff;
      text-decoration: underline; } }

  .icons a {
    font-size: 32px;
    &:hover {
      text-shadow: 3px 3px 7px rgba(255, 255, 255, 0.25); } }


  .scroll-down {
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 50px;
    width: 30px;
    bottom: 60px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 20px;
    cursor: pointer;
    outline: none;

    &:before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: '';
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: #fff;
      border-radius: 100%;
      animation: scroll-down_btn-animation 2s infinite;
      box-sizing: border-box; } }

  @keyframes scroll-down_btn-animation {
    0% {
      transform: translate(0, 0);
      opacity: 0; }
    40% {
      opacity: 1; }
    80% {
      transform: translate(0, 20px);
      opacity: 0; }
    100% {
      opacity: 0; } }

  @media (max-width: 767.98px) {
    .scroll-down {
      display: none; } } }
